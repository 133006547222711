import i18n from "i18next";
import { Routes as AppRoutes } from "@/constants/routes";
import { Languages } from "@/constants/languages";

export const getLanguage = () => {
    let lng = i18n.language;

    if (typeof window !== "undefined" && window.location) {
        const isOfferUrl = window.location.pathname.includes(
            `/${AppRoutes.Both}/${AppRoutes.Offer}`
        );

        if (isOfferUrl) {
            lng = Languages.English;
        }
    } else {
        lng = Languages.English;
    }

    return lng;
};
