export const IS_PROD_BUILD = process.env.NODE_ENV === "production";
// true only for production url
export const IS_PROD_ENV = process.env.REACT_APP_IS_PRODUCTION === "true" || false;

export const APPLE_PAY_MERCHANT_ID = IS_PROD_ENV
    ? "e1079a8ed542450280e3e2ebfef4d96d"
    : "769dece89c0e4f358020c9f10e1ca323";

export const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE || "b8c02694132cbaf5489399c6c8b4178b";
export const PALTA_DATA_PLATFORM_KEY =
    process.env.REACT_APP_PALTA_DATA_PLATFORM_KEY || "2d8b27a03a2943a081295849c70ff1fa";

export const SENTRY_APPLICATION_KEY = "zing-web-onboarding";
